var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "LBL0001974" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editable && !_vm.disabled && !_vm.completeCheck,
                        expression: "editable&&!disabled&&!completeCheck",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isComplete,
                      url: _vm.completeUrl,
                      param: _vm.relatedWork,
                      mappingType: "PUT",
                      label: "LBLCOMPLETE",
                      icon: "check",
                    },
                    on: {
                      beforeAction: _vm.completeRelatedWork,
                      btnCallback: _vm.completeRelatedWorkCallback,
                    },
                  }),
                  _vm.editable && !_vm.disabled && !_vm.completeCheck
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.relatedWork,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveRelatedWork,
                          btnCallback: _vm.saveRelatedWorkCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    disabled: _vm.disabled || _vm.completeCheck,
                    label: "LBL0001967",
                    name: "actionScheduleCompleteDate",
                  },
                  model: {
                    value: _vm.relatedWork.actionScheduleCompleteDate,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.relatedWork,
                        "actionScheduleCompleteDate",
                        $$v
                      )
                    },
                    expression: "relatedWork.actionScheduleCompleteDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                !_vm.emergencyCheck
                  ? [
                      _c("c-field", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled || _vm.completeCheck,
                          data: _vm.relatedWork,
                          deptValue: "actionDeptCd",
                          type: "dept_user",
                          label: "LBLMANAGER",
                          name: "actionUserId",
                        },
                        model: {
                          value: _vm.relatedWork.actionUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.relatedWork, "actionUserId", $$v)
                          },
                          expression: "relatedWork.actionUserId",
                        },
                      }),
                    ]
                  : [
                      _c("c-field", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          disabled: _vm.disabled || _vm.completeCheck,
                          data: _vm.relatedWork,
                          deptValue: "actionDeptCd",
                          type: "dept_user",
                          label: "LBLMANAGER",
                          name: "actionUserId",
                        },
                        model: {
                          value: _vm.relatedWork.actionUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.relatedWork, "actionUserId", $$v)
                          },
                          expression: "relatedWork.actionUserId",
                        },
                      }),
                    ],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled || _vm.completeCheck,
                    label: "LBL0001975",
                    name: "actionCompleteDate",
                  },
                  model: {
                    value: _vm.relatedWork.actionCompleteDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.relatedWork, "actionCompleteDate", $$v)
                    },
                    expression: "relatedWork.actionCompleteDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    disabled: _vm.disabled || _vm.completeCheck,
                    data: _vm.moc,
                    deptValue: "checkDeptCd",
                    type: "dept_user",
                    label: "LBL0001950",
                    name: "checkUserId",
                  },
                  model: {
                    value: _vm.relatedWork.checkUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.relatedWork, "checkUserId", $$v)
                    },
                    expression: "relatedWork.checkUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled || _vm.completeCheck,
                    rows: 3,
                    label: "LBL0001976",
                    name: "checkContents",
                  },
                  model: {
                    value: _vm.relatedWork.checkContents,
                    callback: function ($$v) {
                      _vm.$set(_vm.relatedWork, "checkContents", $$v)
                    },
                    expression: "relatedWork.checkContents",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          staticClass: "q-mt-sm",
          attrs: {
            title: "LBL0001977",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.relatedWork.relatedWorkDocuments,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && !_vm.disabled && !_vm.completeCheck,
            selection: "multiple",
            rowKey: "sopChangeRelatedWorkDocumentId",
          },
          on: { linkClick: _vm.linkClick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled && !_vm.completeCheck
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addDocument },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled && !_vm.completeCheck
                    ? _c("c-btn", {
                        attrs: { label: "LBLEXCEPT", icon: "remove" },
                        on: { btnClicked: _vm.removeDocument },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }